import { firebaseConfig, endPointUrl } from "../libs/fbConfig";
import app from "firebase/compat/app";
import fbRef from "../libs/fbRef";
import "firebase/compat/auth";
import "firebase/compat/database";
import { eventChannel } from "redux-saga";
import { removeItem, setItem, getItem } from "../libs/encryptedStorage";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import authHelper from "../okta/page"
const superagent = require("superagent");
import { oktaRedirectUrl } from "../libs/fbConfig";

import 'dayjs/plugin/timezone';
dayjs.extend(require('dayjs/plugin/timezone'));

function initMasterConfig() {
    try {
        return app.initializeApp(firebaseConfig);
    } catch (err) {
        console.log("initMasterConfig", err)
    }
};

function signInWithEmail() {
    var promise = new Promise(function (resolve, reject) {
        app.auth()
            .signInWithEmailAndPassword("booking@email.com", "test@123")
            .then((authUser) => {
                resolve(authUser);
            })
            .catch(function (error) {
                console.log("auth user not found", error);
                resolve(null);
            });
    });
    return promise;
}

function getNewUser(userId) {
    let db = app.database();
    let dbRef = db.ref(fbRef.newUser).orderByChild("signInProvider").equalTo(userId);
    let userObj;
    let promise = new Promise((resolve, reject) => {
        dbRef.once("value").then(snap => {
            if (snap.val()) {
                snap.forEach(element => {
                    resolve(element.val());
                    return;
                });
            }
            else {
                resolve(null);
            }
        })
    });
    return promise;
}

function fetchSchoolConfig(schoolName) {
    let db = app.database();
    let dbRef = db.ref(fbRef.schools).orderByChild("centerId").equalTo(schoolName);
    let schoolObj;

    let promise = new Promise((resolve, reject) => {
        dbRef.once('value').then(snap => {
            if (snap.val()) {
                snap.forEach(element => {
                    resolve(element.val());
                    return;
                });
            }
        }).catch(err => {
            console.log("fetchSchoolConfig", err)
            reject(err);
        });
    });
    return promise;
}

function initSecondaryDb(schoolConfig, schoolName) {
    // const secondaryConfig = {
    //     clientId: schoolConfig.clientID,
    //     appId: schoolConfig.secondaryWebAppId,
    //     apiKey: schoolConfig.apiKey,
    //     databaseURL: schoolConfig.databaseUrl,
    //     storageBucket: schoolConfig.storageBucket,
    //     messagingSenderId: schoolConfig.gcmSenderID,
    //     projectId: schoolConfig.projectId,
    // };
    const secondaryConfig = {
        apiKey: schoolConfig.apiKey,
        authDomain: schoolConfig.authDomain,
        databaseURL: schoolConfig.databaseUrl,
        projectId: schoolConfig.projectId,
        storageBucket: schoolConfig.storageBucket,
        messagingSenderId: schoolConfig.messagingSenderId,
        appId: schoolConfig.appId
    }

    try {
        return app.app(schoolName);
    } catch (e) {
        return app.initializeApp(secondaryConfig, schoolName);
    }
}

function loginSecondaryDb(schoolConfig, secondaryApp) {
    let promise = new Promise((resolve, reject) => {
        secondaryApp
            .auth()
            .signInWithEmailAndPassword(
                schoolConfig.username,
                schoolConfig.password
            )
            .then(function () {
                console.log("secondaryDB logged in")
                resolve(true);
            }).catch(err => {
                console.log("Failed to login secondary database", err);
                reject(err);
            })
    });
    return promise;
}

async function logout() {
    let auth0 = authHelper.getauth0Instance(null)
    await auth0.logout({
        logoutParams: {
            returnTo: oktaRedirectUrl
        }
    });
    initMasterConfig();
    clearLocalStorage();
    let promise = new Promise((resolve, reject) => {
        app.auth().signOut().then(res => {
            resolve(true)
        }).catch(err => {
            console.log("Failed to logout", err);
            reject(false);
        })
    });
    return promise;
}

function getNewUserWithChannel(userId) {
    let db = app.database();
    let dbRef = db.ref(fbRef.newUser).orderByChild("signInProvider").equalTo(userId);
    return eventChannel((emit) => {
        dbRef.on("value", (snap) => {
            let userObj;
            if (snap.val()) {
                snap.forEach(element => {
                    userObj = element.val();
                    return;
                });
                emit(userObj);
            }
        });
        return () => {
            dbRef.off();
            console.log("closing new user channel");
        };
    });
}

function clearLocalStorage() {
    removeItem("user");
    removeItem("schoolConfig");
    removeItem("schoolName");
    removeItem("loggedInEncodedEmail");
    removeItem("teacher");
    removeItem("teacherList");
    removeItem("student");
}

function fetchSchoolConfigWithChannel(schoolName) {
    ;
    let db = app.database();
    let dbRef = db.ref(fbRef.schools).child(schoolName);
    return eventChannel((emit) => {
        dbRef.on("value", (snap) => {
            let schoolObj;
            if (snap.val()) {
                schoolObj = snap.val();
            }
            ;
            emit(schoolObj);
        },
            function (error) {
                // This function will be called if there's an error
                console.error("Error fetching data:", error);
            }
        );
        return () => {
            dbRef.off();
            console.log("closing school config channel");
        };
    });
}
function verifyPhoneNumber(numberWithCode, recaptcha) {
    try {
        window.recaptchaVerifier = new app.auth.RecaptchaVerifier(recaptcha, {
            size: "invisible",
            // 'size': 'normal',
            callback: function (response) {
                console.log("captcha response", response);
            },
            "expired-callback": function () { },
        });
        var promise = new Promise(function (resolve, reject) {
            app.auth()
                .signInWithPhoneNumber(numberWithCode, window.recaptchaVerifier)
                .then((confirmationResult) => {
                    resolve(confirmationResult);
                })
                .catch((error) => {
                    console.log("failed to send verification code", error.code);
                });
        });
        return promise;
    } catch (err) {
        console.log("failed to send verification code", err);
    }
}

function loginUsingPhoneNumber(confirmationResult, verificatonCode) {
    try {

        var promise = new Promise(function (resolve, reject) {
            confirmationResult
                .confirm(verificatonCode)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    console.log("failed to login with phone number", error.code);
                });
        });
        return promise;
    } catch (err) {
        console.log("failed to login with phone number", err);
    }
}

function loginWithGoogle() {
    try {
        var promise = new Promise(function (resolve, reject) {
            var provider = new app.auth.GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account'
            });
            provider.addScope('email');
            app.auth()
                .signInWithPopup(provider)
                .then((result: any) => {
                    if (result?.additionalUserInfo?.profile?.email) {
                        resolve(result);
                    } else {
                        reject("Email not found")
                    }
                })
                .catch((error) => {
                    reject("Email not found")
                })
        });
        return promise;
    } catch (err) {
        console.log("failed to login with google", err);
    }
}
function fetchTeacher(secondaryApp, branchPath, userId) {
    // userId = "-NxMqR4W7rdfPh8lYzAr";
    var promise = new Promise(function (resolve, reject) {
        let teacherRef = secondaryApp.database().ref(branchPath + "/teachers").child(userId);
        var teacher = teacherRef.once("value").then(function (teacherSnapshot) {
            return teacherSnapshot.val();
        });
        resolve(teacher);
    })
    return promise;
}

function fetchEntityByPromise(secondaryApp, branchPath, nodeName, userId) {

    var promise = new Promise(function (resolve, reject) {
        let ref = secondaryApp.database().ref(branchPath + nodeName).child(userId);
        var entity = ref.once("value").then(function (snap) {
            return snap.val();
        });
        resolve(entity);
    })
    return promise;
}

function fetchStudents(secondaryApp, branchPath) {
    var promise = new Promise(function (resolve, reject) {
        let teacherRef = secondaryApp.database().ref(branchPath + "/students");

        teacherRef.once("value").then(function (snapshot) {
            var students = [];
            snapshot?.forEach((snap) => {
                students.push(snap.val());
            })
            resolve(students);
        });
    })
    return promise;
}

function triggerAuthStateListener() {
    app.auth().onAuthStateChanged((user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/v8/firebase.User
            var uid = user.uid;
            console.log("uid", uid)
            // ...
        } else {
            console.log("user not found")
            // User is signed out
            // ...
        }
    });
}

function isTokenExpired(token) {
    var decoded = jwtDecode(token);
    if (decoded.exp.toString().length == 10) {
        decoded.exp = decoded.exp * 1000;
    } else {
        console.log("length is more than 10")
    }

    let isExpired = false;
    if (dayjs(decoded.exp).subtract(10, 'minute').isBefore(dayjs(), 'minutes')) {
        isExpired = true;
    };
    return isExpired;
}
function catchTokenError(err) {
    setTimeout(() =>
        logout()
        , 100);
}

function getAuthToken() {
    let existingToken = getItem("authToken");
    let isExpired = false;
    if (existingToken) {
        isExpired = isTokenExpired(existingToken);
    } else {
        isExpired = true;
    }



    let promise = new Promise(function (resolve, reject) {
        {/**test cases */ }
        // user not found
        // token expired
        // network error
        // other failure
        // reset password
        if (!isExpired && existingToken) { resolve(existingToken); return; };
        var unsubscribeListner = app.auth().onAuthStateChanged(user => {
            if (user) {
                user
                    .getIdToken(true)
                    .then(function (token) {
                        console.log("Token on interval-->", token);
                        resolve(token);
                        setItem("authToken", token);
                        unsubscribeListner();
                    }).catch((err) => {
                        if (err && err.code && err.code === "auth/network-request-failed") {
                            console.error("error in getting token on interval", err);
                            resolve(existingToken);
                        } else {
                            reject(err);
                            catchTokenError(err)
                        }
                    });
            } else {
                catchTokenError(new Error("User not found"))
            }
        });
    });
    return promise;
}

function signupAccount(email,
    password,
    countryCode,
    phoneNumber,
    name,
    address,
    schoolName,
    firebase,
    selectedGender) {
    let userEmail = replaceAll(email, "@", "%40");
    let encodedEmail = replaceAll(userEmail, ".", "%2E");
    let signUpEndPointUrl = endPointUrl + "bookingApi/signup/school";
    console.log("encoded email", encodedEmail);
    let obj = {
        address: address,
        email: email,
        schoolName: schoolName,
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        password: password,
        name: name,
        encodedEmail: encodedEmail,
        timezone: dayjs.tz.guess(),
        gender: selectedGender,
        platform: "web",
        updatedOn: dayjs().valueOf()
    };
    // let db = app.database();
    var p1 = new Promise(function (resolve, reject) {
        superagent
            .post(signUpEndPointUrl)
            .send(obj)
            .set("accept", "json")
            .end((err, res) => {
                console.log("res", res);
                if (res) {
                    resolve(res);
                }

                if (err) {
                    reject(err);
                }
            });
    });
    return p1;
}
function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}


function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export const loginApi = {
    initMasterConfig,
    signInWithEmail,
    getNewUser,
    fetchSchoolConfig,
    initSecondaryDb,
    loginSecondaryDb,
    logout,
    getNewUserWithChannel,
    clearLocalStorage,
    fetchSchoolConfigWithChannel,
    verifyPhoneNumber,
    loginUsingPhoneNumber,
    loginWithGoogle,
    fetchTeacher,
    triggerAuthStateListener,
    signupAccount,
    getAuthToken,
    fetchStudents,
    fetchEntityByPromise
}