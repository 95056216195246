"use client";
import { Auth0Client } from '@auth0/auth0-spa-js';
import { oktaRedirectUrl } from '../libs/fbConfig';
import { notification } from '../util/notification';
import formatMsg from '../util/formatMsg';
import { loginApi } from '../firebase-api/login';
function getauth0Instance(loginType: "sms" | null) {

    let authorizationParams: any = {
        redirect_uri: oktaRedirectUrl,
        audience: "illumine/auth",
    }
    if (loginType === "sms") {
        authorizationParams.connection = "sms"
    }
    const auth0 = new Auth0Client({
        domain: 'dev-70bq18vu4zzunxqo.us.auth0.com',
        clientId: 'FCC4OCNY6h3iyxqMkp6W0TEgByMQ0pjJ',
        authorizationParams,
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        useRefreshTokensFallback: true
    });

    return auth0
}

async function getAuthToken() {
    try {
        const auth0 = new Auth0Client({
            domain: 'dev-70bq18vu4zzunxqo.us.auth0.com',
            clientId: 'FCC4OCNY6h3iyxqMkp6W0TEgByMQ0pjJ',
            authorizationParams: {
                redirect_uri: oktaRedirectUrl,
                audience: "illumine/auth",
            },
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
            useRefreshTokensFallback: true
        });
        let token = await auth0.getTokenSilently()
        return token;
    } catch (err) {
        loginApi.logout();
        console.log("Failure in login flow", err);
        notification("error", formatMsg("sessionExp"));

    }

}


export default { getauth0Instance, getAuthToken }