import { getItem } from "../libs/encryptedStorage";
import en from "../locales/en_US.json";
import ar from "../locales/ar_AR.json";

function getCurrentLocale() {
    let locale = getItem("lang") ? getItem("lang") : "en";
    let direction = getLocaleDirection(locale);

    let message = { en, ar };

    return {
        locale: locale,
        direction: direction,
        message: message[locale]
    }

}

function getLocaleDirection(locale: any) {
    let dir = "ltr";

    switch (locale) {
        case "en":
            dir = "ltr";
            break;

        case "ar":
            dir = "rtl";
            break;

    }
    return dir;
}

export { getCurrentLocale };