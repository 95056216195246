
import { notification } from "antd";

const SentNotification = (type, message, description = "") => {
    try {
        notification[type]({
            message,
            description,
        });
    } catch (error) {
        console.log("notification error ", error);
    }

    return null;
};

export { SentNotification as notification };
