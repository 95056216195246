import CryptoJS from "crypto-js";

// import Bugsnag from "@bugsnag/js";
const passphrase = "password";
export const keyVersion = "1.0.0";
export function setItem(key, data) {
  let ciphertext;
  if (typeof window !== 'undefined') {
    try {
      if (typeof data == "object")
        ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), passphrase);
      else {
        if (typeof data == "boolean" || typeof data == "number") {
          data = data.toString();
        }
        ciphertext = CryptoJS.AES.encrypt(data, passphrase);
      }
      localStorage.setItem(key, ciphertext);

    } catch (err) {
      console.log(err);
      console.log("setItem error", key, data)
      // Bugsnag.notify(err);
    }
  }
}

export function getItem(key) {
  if (typeof window !== 'undefined') {
    try {
      var val = null;
      if (localStorage.getItem(key) && localStorage.getItem(key) != "") {
        const encryptedData = localStorage.getItem(key);
        const decryptedDataBytes = CryptoJS.AES.decrypt(encryptedData, passphrase);
        val = decryptedDataBytes.toString(CryptoJS.enc.Utf8);
      }

      if (val == undefined || val == "") {
        return null;
      } else {
        return val;
      }
    } catch (err) {
      console.log(err);
      // Bugsnag.notify(err);
    }
  }
}

export function removeItem(key) {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
}

export function clear() {
  if (typeof window !== 'undefined') {
    localStorage.clear();
  }
}

export function encryptAllKeys() {
  if (typeof window !== 'undefined') {
    let allLocalStorage = {
      ...localStorage,
    };

    for (let key in allLocalStorage) {
      setItem(key, allLocalStorage[key]);
    }
  }
}
