const colors = {
  color_primary: "#333333",
  color_primarytext: "#FFFFFF",
  v2_Cerulean: "#7413BD",
  v3_Cerulean: "#7413BD",
  v4_Cerulean: "#00ADD1",
  color_black: "#000000",
  color_darkerGrey: "#333333",
  color_darkGrey: "#5B5B5B",
  color_mediumGrey: "#8D8D8D",
  color_lightGrey: "#D0D0D0",
  color_lighterGrey: "#E8E8E8",
  color_lightestGrey: "#F8F8F8",
  color_white: "#FFFFFF",
  color_activeBackground: "#F7ECFF",
  color_activeColor: "#14CCA1",
  color_purple: "#7413BD",
  color_inactiveColor: "#14CCA1",
  primaryBgColor: "#7413BD",
  v2_fuzzy_brown: "#C24E4E",
  blue2D4D84: "#2D4D84",
};
export default colors;
