import { createSlice } from '@reduxjs/toolkit'
export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isSignIn: false,
    isLoading: false,
    firebase: null,
    confirmationResult: undefined,
    operationType: undefined,
    channels: {},
    newUsers: [],
    attachmentProgress: undefined,
    allFilesUploaded: false,
    authSuccess: false,
    authEmail: undefined,
    userSheetLoader: false
  },
  reducers: {
    signIn: (state, action) => {
      state.isLoading = true
    },
    initLogin: (state, action) => {
      state.isLoading = true
    },

    logout: (state, action) => {
      state.isLoading = true;
    },

    requestFailed: (state, action) => {
      state.isLoading = false
    },

    loginSuccess: (state, action) => {
      state.firebase = { ...state.firebase, ...action.payload };
      state.isLoading = false;
      state.operationType = "SIGN_SUCCESS";
    },
    parentSignSuccess: (state, action) => {
      state.firebase = action.payload;
      state.isLoading = false;
      state.operationType = "PARENT_SIGN_SUCCESS";
    },

    logoutSuccess: (state, action) => {
      state.firebase = undefined;
      state.isLoading = false;
      state.newUsers = [];
      state.operationType = "LOGOUT_SUCCESS";
    },

    validateUserLogin: (state, action) => {
      state.isLoading = true;
    },

    updateChannel: (state, action) => {
      let key = action.payload.key;
      let val = action.payload.val;
      state.channels[key] = val;
      console.log("update channel", state.channels, val)
    },

    closeChannels: (state, action) => {
      for (const key in state.channels) {
        if (state.channels[key]) {
          state.channels[key].close();
        }
      }
    },

    closeChannelByKey: (state, action) => {
      let key = action.payload;
      if (state?.channels[key]) {
        console.log("state.channels[key]", state.channels[key])
        // state.channels[key].close();
      }
    },

    updateFirebaseObj: (state, action) => {
      let key = action.payload.key;
      let val = action.payload.val;
      state.firebase = { ...state.firebase, [key]: val };
    },
    signUpAccount: (state, action) => {
      state.isLoading = true
    },
    signUpSuccess: (state, action) => {
      state.isLoading = false;
      state.operationType = "SIGN_UP_SUCCESS";
    },
    attachmentUploadProgress: (state, action: any) => {
      state.allFilesUploaded = action.allFilesUploaded,
        state.attachmentProgress = state.attachmentProgress && action.moreThanOne ? state.attachmentProgress + action.attachmentProgress : action.attachmentProgress
    },
    sliceOperation: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    setNewUsers: (state, action) => {
      state.newUsers = action.payload.newUsers,
        state.operationType = "NEW_USER_SUCCESS";
      state.userSheetLoader = false
    },
    continueWithNewUser: (state, action) => {
      state.userSheetLoader = true
    },
    resetUploadProgress: (state, action) => {
      state.allFilesUploaded = false,
        state.attachmentProgress = undefined
    },
    fetchNewUsers: (state, action) => {
      state.userSheetLoader = true
    },
    authenticationSucess: (state, action) => {
      state.authSuccess = action.payload.authSuccess;
    }
  }
})

export const { signIn,
  requestFailed, logout, loginSuccess, logoutSuccess,
  validateUserLogin,
  closeChannelByKey, closeChannels, updateChannel, updateFirebaseObj, signUpAccount, attachmentUploadProgress,
  sliceOperation, continueWithNewUser, resetUploadProgress, fetchNewUsers, authenticationSucess, initLogin } = loginSlice.actions

export default loginSlice.reducer